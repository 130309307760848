import {
  ADDCALCULATIONDATAFAILURE,
  ADDCALCULATIONDATASUCCESS,
  ADDDASHBORADCMSFAILURE,
  ADDDASHBORADCMSSUCCESS,
  ADDNONUSEDINVEST,
  ADDUSEDINVEST,
  ADDUSEDINVESTFAILURE,
  REFERRALFLAG,
} from "../../actions/types";

const instrumentData = {
  instrumentData: {},
  nonUnsedInstrumentData: {},
  dashboardCmsData: {},
  calculationData: {},
  referralFlag: false,
};

const dashboradReducer = (state = instrumentData, action) => {
  switch (action.type) {
    case ADDUSEDINVEST:
      return {
        ...state,
        instrumentData: { ...state.instrumentData, ...action.payLoad },
      };

    case ADDUSEDINVESTFAILURE:
      return {
        ...state,
        instrumentData: { ...state.instrumentData, ...action.payLoad },
      };

    case ADDNONUSEDINVEST: {
      return {
        ...state,
        nonUnsedInstrumentData: { ...state.instrumentData, ...action.payLoad },
      };
    }

    // dashboard cms
    case ADDDASHBORADCMSSUCCESS: {
      return {
        ...state,
        dashboardCmsData: action.payLoad,
      };
    }

    case ADDDASHBORADCMSFAILURE: {
      return {
        ...state,
        dashboardCmsData: {},
      };
    }

    // retrial info
    case ADDCALCULATIONDATASUCCESS: {
      return {
        ...state,
        calculationData: action.payLoad,
      };
    }

    case ADDCALCULATIONDATAFAILURE: {
      return {
        ...state,
        calculationData: {},
      };
    }

    case REFERRALFLAG: {
      return {
        ...state,
        referralFlag: action.payLoad,
      };
    }
    default:
      return state;
  }
};

export default dashboradReducer;
