import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  typography: {
    fontFamily: [
      "Poppins",
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
  },
  palette: {
    primary: {
      main: "#1A3E6D",
    },
  },
  components: {
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        fullWidth: true,
        size: "large",
        disableElevation: true,
        disableRipple: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "99rem",
        },
        sizeLarge: {
          padding: ".9rem 2.5rem",
          borderRadius: "99rem",
          fontSize: "1.1rem",
          fontWeight: "600",
          textTransform: "none",
        },
        sizeSmall: {
          padding: ".5rem 1.5rem",
          borderRadius: "99rem",
          fontSize: "1.1rem",
          fontWeight: "600",
          textTransform: "none",
        },
        sizeMedium: {
          padding: "11.5px 1.5rem",
          borderRadius: "99rem",
          fontSize: "17px",
          lineHeight: "24px",
          letterSpacing: "-0.4 px",
          fontWeight: "600",
          textTransform: "none",
        },
      },
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
    },
    MuiIcon: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiIconButton: {
      defaultProps: {
        disableRipple: true,
        disableFocusRipple: true,
      },
    },
  },
});

export default theme;
