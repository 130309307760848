import { all } from "redux-saga/effects";

import individualInvest from "./dashboard/individualInvest";
import retrialInfo from "./dashboard/retrialInfo";
import articleSaga from "./learn/articleSaga";
import imageSaga from "./images/imageSaga";

export default function* rootSaga() {
  yield all([individualInvest(), retrialInfo(), articleSaga(), imageSaga()]);
}
