import RetiralPdfDownload from "./index";
import PdfPortfolio from "./pdfPortfolio";
import { RetiralPdfPath } from "../../utils/RoutingConstants";

export const retiralPdfRoute = [
  {
    path: RetiralPdfPath.genratePdf,
    component: RetiralPdfDownload,
    exact: true,
  },
  {
    path: RetiralPdfPath.genratePortfolio,
    component: PdfPortfolio,
    exact: true,
  },
];
