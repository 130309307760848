import React, { useState, useEffect } from "react";
import { Provider } from "react-redux";

import AppRouter from "./router";
import InternetError from "./views/common/Error/InternetError";
import { store, persistor } from "./Redux/Store/store";
import { Encrypt } from "./modules";
import "./assets/styles/_Websitmain.scss";
import { PersistGate } from "redux-persist/integration/react";

store.subscribe(() => {
  if (process.env.REACT_APP_PERSIST_STORE_DATA === "ENABLED") {
    sessionStorage.setItem("_store", Encrypt(store.getState(), "local"));
  } else {
    store.getState();
  }
});

function App() {
  const [onlineStatus, setOnlineStatus] = useState(true);

  useEffect(() => {
    window.addEventListener("offline", () => {
      setOnlineStatus(false);
    });
    window.addEventListener("online", () => {
      setOnlineStatus(true);
    });

    return () => {
      window.removeEventListener("offline", () => {
        setOnlineStatus(false);
      });
      window.removeEventListener("online", () => {
        setOnlineStatus(true);
      });
    };
  }, []);

  useEffect(() => {
    const isFlutterActive = window.flutter_inappwebview !== undefined;
    isFlutterActive
      ? document.body.classList.add("is-flutter")
      : document.body.classList.add("is-webview");
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="app-component">
          {onlineStatus === true ? <AppRouter /> : <InternetError />}
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
