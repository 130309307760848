import {
  ADDIMAGES,
  ADDIMAGESSUCCESS,
  ADDIMAGESFAILURE,
} from "../../actions/types";

const imagesState = {
  imageData: {},
};

const imagesReducer = (state = imagesState, action) => {
  switch (action.type) {
    case ADDIMAGES:
      return {
        ...state,
        imageData: action.payLoad,
      };
    case ADDIMAGESSUCCESS:
      return {
        ...state,
        imageData: action.payLoad,
      };
    case ADDIMAGESFAILURE:
      return {
        ...state,
        imageData: {},
      };
    default:
      return state;
  }
};

export default imagesReducer;
