import { lazy } from "react";

import { LoginAndRegisterPagePaths } from "../../utils/RoutingConstants";

const Login = lazy(() => import("./index"));
const VerifyLoginOtp = lazy(() => import("./VerifyOtp/VerifyOtp"));
const ForgotPassword = lazy(() => import("./forgotPassword/index"));
const SetPassword = lazy(() => import("./forgotPassword/setPassword/index"));
const LoginNow = lazy(() => import("./forgotPassword/loginNow/index"));
const RetiralCalculator = lazy(() => import("./RetiralCalculator/Calculator"));

export const loginPageRouters = [
  {
    path: LoginAndRegisterPagePaths.loginPage,
    component: Login,
    exact: true,
  },
  {
    path: LoginAndRegisterPagePaths.forgetPassword,
    component: VerifyLoginOtp,
    exact: true,
  },
  {
    path: LoginAndRegisterPagePaths.UpdatePassword,
    component: SetPassword,
    exact: true,
  },
  {
    path: LoginAndRegisterPagePaths.forgotPassword,
    component: ForgotPassword,
    exact: true,
  },
  {
    path: LoginAndRegisterPagePaths.successPage,
    component: LoginNow,
    exact: true,
  },
  {
    path: LoginAndRegisterPagePaths.prelogin,
    component: RetiralCalculator,
    exact: true,
  },
];
