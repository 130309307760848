import { lazy } from "react";
import { emptyNavPaths } from "../../utils/RoutingConstants";
const emptyNavPage = lazy(() => import("./index"));
const paths = [
  emptyNavPaths.offerDetails,
  emptyNavPaths.serviceCorner,
  emptyNavPaths.verifyAccount,
  emptyNavPaths.calculator,
  emptyNavPaths.webinars,
  emptyNavPaths.supportPage,
  emptyNavPaths.community,
  emptyNavPaths.legalInfo,
  emptyNavPaths.article,
  emptyNavPaths.webinarDetail,
  emptyNavPaths.notificationMV,
  emptyNavPaths.JoinPlan,
  emptyNavPaths.linkInvestment,
  emptyNavPaths.podcast,
];
export const emptyNavPageRouter = paths.map((ele) => ({
  path: ele,
  component: emptyNavPage,
  exact: true,
}));
