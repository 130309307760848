import { combineReducers } from "redux";
import dashboradReducer from "./dashboard/dashboradReducer";
import learnReducer from "./learn/learnReducer";
import planGoalReducer from "./plan/planReducer";
import imagesReducer from "./images/imageReducer";

const appReducer = combineReducers({
  dashboradReducer,
  learnReducer,
  planGoalReducer,
  imagesReducer,
});

const reducers = (state, action) => {
  return appReducer(state, action);
};

export default reducers;
