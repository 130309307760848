import Button from "@mui/material/Button";
import {
  Document,
  Font,
  Image,
  Link,
  Page,
  PDFDownloadLink,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import { Fragment, useMemo } from "react";
import { useSelector } from "react-redux";
import logo from "../../assets/images/PDF/PDF-logo.png";
import mobilemockup from "../../assets/images/Report/Appdownload.png";
import appstore from "../../assets/images/Report/appstore.png";
import Goalill from "../../assets/images/Report/Goal-ill.png";
import homeill from "../../assets/images/Report/Home-ill.png";
import playstore from "../../assets/images/Report/playstoer.png";
import productill from "../../assets/images/Report/product-ill.png";
import Retiraladvisorill from "../../assets/images/Report/Retiral Advisor-ill.png";
import { productName, hyperLink, productLink } from "../../utils/constants";
import {
  convertRetiralScore,
  local,
  mapCheck,
  numToValue,
} from "../../utils/Utils";
import poppins from "./assets/poppins.ttf";
import Satisfy from "./assets/Satisfy.ttf";
import Inter from "./assets/Inter.ttf";
import { pdfStyleObj } from "./pdfStyle";
import InterBold from "./assets/InterBold.ttf";
import PublicSansItalic from "./assets/PublicSansItalic.ttf";
import useMediaQuery from "@mui/material/useMediaQuery";
// src/

Font.register({
  family: "Poppins",
  format: "truetype",
  src: poppins,
});
Font.register({
  family: "Satisfy",
  format: "truetype",
  src: Satisfy,
});
Font.register({
  family: "Inter",
  format: "truetype",
  src: Inter,
});
Font.register({
  family: "InterBold",
  format: "truetype",
  src: InterBold,
});
Font.register({
  family: "PublicSansItalic",
  format: "truetype",
  src: PublicSansItalic,
});

const styles = StyleSheet.create(pdfStyleObj);

const RetiralPdf = (props) => {
  const firstName = JSON.parse(props?.profileData)?.individual_first_name;
  const required_corpus = props?.calculationData?.retrialData || {};

  const investedProduct = props?.calculationData?.investedInstruments || {};
  const investProductCard = props?.dashboardCmsData?.investPage || [];
  return (
    <>
      <Document style={{ padding: "0rem" }}>
        {/* ---------------page 1--------------- */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headlogo}>
            <Image style={styles.logo} src={logo} />
          </View>

          <View style={styles.aboutuser}>
            <Text style={styles.DMtext}>Dear {firstName},</Text>
            <Text style={styles.abouttext}>
              Life99 endeavors to ensure that post retirement life is as
              beautiful, if not more, compared to when you were earning.
            </Text>
            <Text style={styles.abouttext}>
              Basis the data you have provided us, here’s your
            </Text>
          </View>

          <View>
            <Image style={styles.homeill} src={homeill} />
          </View>

          <View style={styles.graph}>
            <View style={styles.HomegraphLHS}>
              <View style={styles.RetiralScoreBox}>
                <Text style={styles.RetiralScoreHeadText}>
                  Retiral Score{" "}
                  {convertRetiralScore(required_corpus["Retial Score"])}
                </Text>
              </View>
            </View>
            <View style={styles.gridgraph}>
              {mapCheck(investProductCard) &&
                // eslint-disable-next-line
                investProductCard.map((value, index) => {
                  if (value?.investUpdateShow) {
                    return (
                      <View style={styles.gridgraphitm} key={index}>
                        <View style={styles[value?.header]}></View>
                        <Text style={styles.graphval}>
                          {productName[value?.header]}{" "}
                          <Text style={styles.RUsign}>₹</Text>{" "}
                          {Object.keys(investedProduct).includes(value?.header)
                            ? numToValue(
                                investedProduct[value?.header]?.current
                              )
                            : 0}
                        </Text>
                      </View>
                    );
                  }
                })}
            </View>
          </View>

          <View style={styles.graphfooterdiv}>
            <Link
              style={styles.AddUpdateBtn}
              src={props?.hyperLink?.addInstrumnet}
            >
              Add/Update investments
            </Link>
            <Text style={styles.graphfootertext}>
              *Add/Update investment to update your retiral score
            </Text>
          </View>
          {required_corpus["Retial Score"] > 100 ? (
            <View style={styles.homefooterborder}>
              <Text style={styles.Distext}>
                At your retirement age -{" "}
                <Text style={styles.homefooterbold}>
                  {" "}
                  {props?.userData?.retirement_age}
                </Text>{" "}
                years, you will have{" "}
                {convertRetiralScore(required_corpus["Retial Score"])} of funds
                required to maintain same standard of living post retirement,
                for the next{" "}
                <Text style={styles.homefooterbold}>
                  {" "}
                  {props?.userData?.survival_age -
                    props?.userData?.retirement_age}{" "}
                  years!
                </Text>{" "}
              </Text>
              <Text style={styles.Distext}>
                Your retiral score is calculated on the basis of your annual
                salary <Text style={styles.RUsign}>₹</Text>{" "}
                {numToValue(props?.userData?.income)}, current corpus, current
                age, retirement age and survival age.
              </Text>
            </View>
          ) : (
            <View style={styles.homefooterborder}>
              <Text style={styles.Distext}>
                At your retirement age -{" "}
                <Text style={styles.homefooterbold}>
                  {" "}
                  {props?.userData?.retirement_age}
                </Text>{" "}
                years, you will only have{" "}
                {convertRetiralScore(required_corpus["Retial Score"])} of funds
                required to maintain same standard of living post retirement,
                for the next{" "}
                <Text style={styles.homefooterbold}>
                  {" "}
                  {props?.userData?.survival_age -
                    props?.userData?.retirement_age}{" "}
                  years.
                </Text>{" "}
              </Text>
              <Text style={styles.Distext}>
                Your retiral score is calculated on the basis of your annual
                salary <Text style={styles.RUsign}>₹</Text>{" "}
                {numToValue(props?.userData?.income)}, current corpus, current
                age, retirement age and survival age.
              </Text>
            </View>
          )}
        </Page>
        {/* ---------------page 2--------------- */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headlogo} fixed>
            <Image style={styles.logo} src={logo} />
          </View>

          <View style={styles.ProductheadDiv}>
            <View style={styles.Producthead}>
              <View>
                <Text style={styles.Productheading}>
                  Your current corpus is <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(required_corpus["Current Corpus"])}
                </Text>
                <Text style={styles.Productext}>
                  and your retirement corpus requirement is{" "}
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(required_corpus["Required Corpus"])}
                </Text>
              </View>
              <View>
                <Image style={styles.productill} src={productill} />
              </View>
            </View>

            <View style={styles.cardmain}>
              <Text style={styles.Productheading}>
                Update now to retire well in the below products
              </Text>

              <View style={styles.ProductCardBase}>
                {/* ----------product catd-1 -------------- */}
                {mapCheck(props?.dashboardCmsData?.investPage) &&
                  // eslint-disable-next-line
                  props?.dashboardCmsData?.investPage?.map((value, index) => {
                    if (value?.investUpdateShow) {
                      return (
                        <View style={styles.ProductCard} key={index}>
                          <View style={styles.ProductCardHead}>
                            <View style={styles.ProductNI}>
                              <Text style={styles.ProductName}>
                                {productName[value?.header]}{" "}
                              </Text>
                            </View>
                            <Link
                              style={styles.AddUpdateBtnProduct}
                              src={props?.hyperLink?.addInstrumnet}
                            >
                              {Object.keys(investedProduct).includes(
                                value?.header
                              )
                                ? "Update"
                                : "Add"}
                            </Link>
                          </View>

                          <View style={styles.ProductCorpusdata}>
                            <View>
                              <Text style={styles.ProductCorpustitle}>
                                Current corpus
                              </Text>
                              <Text style={styles.ProductCorpusMoney}>
                                <Text style={styles.RUsign}>₹</Text>{" "}
                                {numToValue(
                                  investedProduct[value?.header]?.current
                                ) || 0}
                              </Text>
                            </View>
                            <View>
                              <Text style={styles.ProductCorpustitle}>
                                Retirement corpus
                              </Text>
                              <Text style={styles.ProductCorpusMoneyLi}>
                                <Text style={styles.RUsign}>₹</Text>{" "}
                                {numToValue(
                                  investedProduct[value?.header]?.retirement
                                ) || 0}
                              </Text>
                            </View>
                          </View>
                        </View>
                      );
                    }
                  })}
              </View>

              <View style={styles.margintop}>
                <Text style={styles.ProductFootertext}>
                  1. if any of current corpus values is incorrect,{" "}
                  <Link
                    style={styles.notelink}
                    src={props?.hyperLink?.calculator}
                  >
                    click here to edit
                  </Link>
                </Text>
                <Text style={styles.ProductFootertext}>
                  2. rate of return has been assumed across financial
                  instruments{" "}
                </Text>
                <Text style={styles.ProductFootertext}>
                  3. Annuities is a pension product intended for retirement
                  security. Hence, it has not been added to current corpus and
                  it has been adjusted in retirement corpus requirement
                </Text>
              </View>
            </View>
          </View>
        </Page>

        {/* ---------------page 4---------------  */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headlogo} fixed>
            <Image style={styles.logo} src={logo} />
          </View>
          <Text style={styles.InvestProductDataHead}>
            Add accurate expenses
          </Text>
          <Text style={styles.InvestProductDataHeadText}>
            Your expenses have also been charted by default as the following :
          </Text>

          <View style={styles.InvestProductDataBase}>
            <View style={styles.InvestProductbase}>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>EMI</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[0] || 0)}{" "}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>House rent</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[1] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Utility</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[2] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Children Related</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[3] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Medical + Health</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[4] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Domestic Help</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[5] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Conveyance</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[6] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Food & Beverages</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[7] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Leisure</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[8] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>Travel + Vacation</Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[9] || 0)}
                </Text>
              </View>
              <View style={styles.InvestProductgrid}>
                <Text style={styles.InvestProductname}>
                  Others (Insurance etc.)
                </Text>
                <Text style={styles.InvestProductmoney}>
                  <Text style={styles.RUsign}>₹</Text>{" "}
                  {numToValue(props?.expenseArr[10] || 0)}
                </Text>
              </View>
            </View>
          </View>

          <Text style={styles.ProductFootertext}>
            1. if any expense is incorrect,{" "}
            <Link src={props?.hyperLink?.calculator}>click here to edit</Link>
          </Text>
        </Page>
        {/* ---------------page 5--------------- */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headlogo}>
            <Image style={styles.logo} src={logo} />
          </View>

          <View style={styles.goalHeadBox}>
            <View>
              <Text style={styles.goalHeadtext}>
                Goal setting can help you determine how well you’re progressing
                towards your most important goals!
              </Text>
            </View>
            <Image style={styles.goalHeadill} src={Goalill} />
          </View>

          <View style={styles.goalgraph}>
            <View style={styles.goalgraphrow}>
              <View style={styles.goalgraphcol}>
                <Text>My Life goals</Text>
              </View>
              <View style={styles.goalgraphcol}>
                <Text>Required amount</Text>
              </View>
              <View style={styles.goalgraphcol}>
                <Text>Age</Text>
              </View>
            </View>
            {/* //goaltable data */}
            {mapCheck(props?.goalReducer?.goals) &&
              props?.goalReducer?.goals?.map((value, index) => {
                return (
                  <View style={styles.goalgraphrowval} key={index}>
                    <View style={styles.goalgraphcolval}>
                      <Text>{value?.user_goal_name}</Text>
                    </View>
                    <View style={styles.goalgraphcolval}>
                      <Text style={styles.RUsign}>
                        ₹ {numToValue(value?.user_goal_amount)}
                      </Text>
                    </View>
                    <View style={styles.goalgraphcolval}>
                      <Text>{value?.user_goal_fullfillment}</Text>
                    </View>
                  </View>
                );
              })}

            <View style={styles.goalgraphcta}>
              <Text style={styles.goalgraphctatext}>
                Add new goals and plan for better future
              </Text>
              <Link style={styles.goalgraphctaBtn} src={props?.hyperLink?.plan}>
                Add/Update Goals
              </Link>
            </View>
          </View>
        </Page>
        {/* ---------------page 6--------------- */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headlogo}>
            <Image style={styles.logo} src={logo} />
          </View>

          <View style={styles.NeededHead}>
            {required_corpus["Retial Score"] > 100 ? (
              <Text style={styles.NeededHeadtext}>
                Nothing extra needed to be done by you – you are all set – enjoy
                the best part of your life!
              </Text>
            ) : (
              <Text style={styles.NeededHeadtext}>
                To work towards your retirement corpus goal, connect now with
                your Retirement Consultant!
              </Text>
            )}

            {/* <Text> Graph</Text> */}
            <View style={styles.RetiralScoreBoxFY}>
              <Text style={styles.RetiralScoreText}>Retiral Score </Text>
              <Text style={styles.RetiralScorevla}>
                {convertRetiralScore(required_corpus["Retial Score"])}
              </Text>
            </View>
          </View>

          {/* need all below product suggection comment */}
          <View style={styles.summarycard}>
            <Text style={styles.summarycardhead}>5% Return on Savings</Text>
            <View style={styles.summarycardREgrid}>
              <Text style={styles.summarycardRE}>Invest 100% in Debt</Text>
            </View>

            <View style={styles.summarycardproduct}>
              {/* <Text style={styles.summarycardproducthead}>
                {" "}
                Products suggestion:
              </Text> */}

              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay FMP, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed return savings plan
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (100% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  style={styles.summarycardproductag}
                  src={props?.productLink?.sanchayFmp}
                >
                  Invest Now
                </Link>
              </View>
            </View>
          </View>

          <View style={styles.summarycard}>
            <Text style={styles.summarycardhead}>6% Return on Savings</Text>

            <View style={styles.summarycardREgrid}>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>Invest 80% in Debt and</Text>
              </View>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>20% in MF</Text>
              </View>
            </View>

            <View style={styles.summarycardproduct}>
              {/* <Text style={styles.summarycardproducthead}>
                Products Suggestion:
              </Text> */}

              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay FMP, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed return savings plan
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (80% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayFmp}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay Par, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed returns + bonuses
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (20% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayPar}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
            </View>
          </View>
          <View style={styles.summarycard}>
            <Text style={styles.summarycardhead}>7% Return on Savings</Text>

            <View style={styles.summarycardREgrid}>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>Invest 70% in Debt,</Text>
              </View>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>20% in MF and</Text>
              </View>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>10% in Equity</Text>
              </View>
            </View>

            <View style={styles.summarycardproduct}>
              {/* <Text style={styles.summarycardproducthead}>
                Products Suggestion:
              </Text> */}

              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay FMP, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed return savings plan
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (70% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayFmp}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay Par, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed returns + bonuses
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (20% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayPar}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Click 2 Wealth, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      market returns + insurance cover
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (10% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.clickToWealth}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
            </View>
          </View>
        </Page>
        {/* ---------------page 6--------------- */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headlogo}>
            <Image style={styles.logo} src={logo} />
          </View>

          <View style={styles.summarycardNOborder}>
            <Text style={styles.summarycardhead}>8% Return on Savings</Text>

            <View style={styles.summarycardREgrid}>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>Invest 40% into Debt,</Text>
              </View>

              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>30% into MF and</Text>
              </View>

              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>30% in Equity</Text>
              </View>
            </View>

            <View style={styles.summarycardproduct}>
              {/* <Text style={styles.summarycardproducthead}>
                Products Suggestion:
              </Text> */}

              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay FMP, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed return savings plan
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (40% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayFmp}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay Par, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed returns + bonuses
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (30% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayPar}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Click 2 Wealth, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      market returns + insurance cover
                    </Text>{" "}
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (30% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.clickToWealth}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
            </View>
          </View>

          <View style={styles.summarycardNOborder}>
            <Text style={styles.summarycardhead}>9% Return on Savings</Text>

            <View style={styles.summarycardREgrid}>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>Invest 20% in Debt,</Text>
              </View>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>40% in MF and</Text>
              </View>
              <View style={{ marginRight: "4px" }}>
                <Text style={styles.summarycardRE}>40% in Equity</Text>
              </View>
            </View>

            <View style={styles.summarycardproduct}>
              {/* <Text style={styles.summarycardproducthead}>
                Products Suggestion:
              </Text> */}

              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay FMP, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed return savings plan
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (20% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayFmp}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Sanchay Par, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      guaranteed returns + bonuses
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (40% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.sanchayPar}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
              <View style={styles.summarycardproductitems}>
                <View>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.InterBold}>Click 2 Wealth, </Text>
                    <Text style={{ fontSize: "11px" }}>
                      market returns + insurance cover
                    </Text>
                  </Text>
                  <Text style={styles.summarycardproducname}>
                    <Text style={styles.PublicSansItalic}>
                      (40% allocation)
                    </Text>
                  </Text>
                </View>
                <Link
                  src={props?.productLink?.clickToWealth}
                  style={styles.summarycardproductag}
                >
                  Invest Now
                </Link>
              </View>
            </View>
          </View>

          <View style={styles.RetiralAdvisorbase}>
            <View style={styles.RetiralAdvisorData}>
              <Text style={styles.RetiralAdvisortext}>
                For further development of a retirement advisory plan and to
                know more about the best way to plan for future, please reach
                out to your retirement advisor at:
              </Text>
              <Text style={styles.RetiralAdvisortextEC}>
                Email ID : customercare_life99@hdfcpension.com
              </Text>
              <Text style={styles.RetiralAdvisortextEC}>
                Mobile number : 08916687777
              </Text>
              <Link
                style={styles.RetiralAdvisorBTN}
                src={props?.hyperLink.advisor}
              >
                Retiral Advisor
              </Link>
            </View>
            <View>
              <Image
                style={styles.RetiralAdvisorimg}
                src={Retiraladvisorill}
              ></Image>
            </View>
          </View>
        </Page>
        {/* ---------------page 7--------------- */}
        <Page size="A4" style={styles.page}>
          <View style={styles.headlogo}>
            <Image style={styles.logo} src={logo} />
          </View>

          <View style={styles.AppDownload}>
            <View>
              <Text style={styles.AppDownloadText}>Plan your</Text>
              <Text style={styles.AppDownloadText}>retirement well</Text>
              <View style={styles.AppDownloadbtngroup}>
                <Link src="https://play.google.com/store/apps/details?id=com.hdfclife.life99">
                  <Image style={styles.AppDownloadBTN} src={appstore}></Image>
                </Link>
                <Link src="https://play.google.com/store/apps/details?id=com.hdfclife.life99">
                  <Image style={styles.AppDownloadBTNP} src={playstore}></Image>
                </Link>
              </View>
            </View>
            <View>
              <Image style={styles.mobilemockup} src={mobilemockup}></Image>
            </View>
          </View>
        </Page>
      </Document>
    </>
  );
};

const RetiralPdfDownload = (props) => {
  const desktop = useMediaQuery("(min-width:600px)");
  const dashboradReducer = useSelector(
    (selector) => selector?.dashboradReducer
  );
  const goalReducer = useSelector((selector) => selector?.planGoalReducer);
  const calcData = dashboradReducer?.calculationData;
  const profileData = localStorage.getItem("individualInfo") || {};
  const dashboardCmsData = dashboradReducer?.dashboardCmsData || {};
  const calculationData = calcData?.calc_calculated || {};
  const userData = calcData?.calc_question_data || {};
  const expenseArr = calcData?.calc_exp_list || [];
  const goalCorpus = calcData?.calc_goal || {};

  const imgBaseUrl = process.env.REACT_APP_CMS_API;
  // const imgBaseUrl = "http://localhost:8080/api-mvp/mvp/";
  let ind_id = JSON.parse(local.getItem("individualInfo"))?.individual_id;

  let buttonStyle = {
    background: " #ffffff",
    border: "1px solid #1a3e6d",
    boxShadow: "0px 8px 16px -6px rgba(0, 82, 103, 0.32)",
    borderRadius: "28px",
    color: "#1a3e6d",
    fontWeight: "600",
    fontSize: "17px",
    width: desktop ? "400px" : "100%",
    padding: "9px",
    marginTop: desktop ? "0" : "1rem",
  };

  const handleImgDoc = () => {
    let temp = { ...props.pdfData };
    temp["loadDoc"] = true;
    temp["imgCall"] = true;
    props.setPdfData(temp);
    // autoBtnClick();
  };

  useMemo(() => {
    // eslint-disable-next-line
    handleImgDoc();
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {props.pdfData.imgCall ? (
        <PDFDownloadLink
          style={styles.PDFContainer}
          document={
            <RetiralPdf
              profileData={profileData}
              dashboardCmsData={dashboardCmsData}
              calculationData={calculationData}
              expenseArr={expenseArr}
              userData={userData}
              imgBaseUrl={imgBaseUrl}
              ind_id={ind_id}
              goalCorpus={goalCorpus}
              hyperLink={hyperLink}
              productLink={productLink}
              goalReducer={goalReducer}
            />
          }
          fileName="RetiralPdf.pdf"
        >
          {({ blob, url, loading, error }) => (
            <>
              {loading ? (
                <Button style={buttonStyle} disabled={true}>
                  Loading document
                </Button>
              ) : (
                <>
                  <Button id="pdf-download" style={buttonStyle}>
                    Download Retirement Planning Report
                  </Button>
                </>
              )}
            </>
          )}
        </PDFDownloadLink>
      ) : (
        <Button style={buttonStyle} disabled={true}>
          Loading document
        </Button>
      )}
    </>
  );
};

export default RetiralPdfDownload;
