export const fullPageData = {
  landingPage: [
    {
      header: "Choose a better way.Buy advisory plan today.",
      main_banner:
        "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Screenshot_from_2022-01-01_13-34-41.png",
    },
    {
      header: "Plan retirement hold the future.Invest well and g",
      main_banner:
        "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Screenshot_from_2022-01-01_13-34-41.png",
    },
    {
      header: "Be smart with your money. Buy advisory plantoday.",
      main_banner:
        "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Add_icon.svg",
    },
  ],
  useStatstics: [
    {
      content: "Retirement Planning Users",
      value: "1,20L",
      sequence: 1,
    },
    {
      content: "Employers Using Life99 To Plan",
      value: "1,5K",
      sequence: 2,
    },
    {
      content: "Happy Employeess",
      value: "20L",
      sequence: 3,
    },
  ],
  planRetirment: {
    section_heading: "Life99 Prepares you for retirement",
    section_image:
      "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/rerirement.png",
    subtext: "sub text",
    point_data: [
      {
        point_text_one: "Check your retirement readiness.....",
        point_one_icon:
          "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/CalendarCheck.svg",
      },
      {
        point_two_text: "Track all your investments at one place",
        point_two_icon:
          "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/MapPin.svg",
      },
      {
        point_three_text: "Boost your financial awareness and planning",
        point_three_icon:
          "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Rocket.svg",
      },
    ],
  },
  userTestimonial: [
    {
      name: "User 1",
      designation: "Designation 1",
      profile_image:
        "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Screenshot_from_2022-01-01_13-57-20.png",
      testimonial:
        "Our entire team is on the same page and moves faster. Slite lets LogDNA staff operate as asingle team across multiple timezones and functions",
      sequence: 0,
    },
    {
      name: "User 2",
      designation: "Designation 2",
      profile_image:
        "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Screenshot_from_2022-01-01_13-57-20.png",
      testimonial:
        "On the same page and moves faster. Slite lets LogDNA staff operate as asingle team across multiple timezones and functions",
      sequence: 0,
    },
    {
      name: "User 3",
      designation: "Designation 3",
      profile_image:
        "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Screenshot_from_2022-01-01_13-57-20.png",
      testimonial:
        "Entire team is on the same page and moves faster. Slite lets LogDNA staff operate as asingle team across multiple timezones and functions",
      sequence: 0,
    },
    {
      name: "User 5",
      designation: "Designation 5",
      profile_image:
        "https://hdfc-static.s3.ap-south-1.amazonaws.com/DEV/2022/1/Screenshot_from_2022-01-01_13-57-20.png",
      testimonial:
        "The same page and moves faster. Slite lets LogDNA staff operate as asingle team across multiple timezones and functions",
      sequence: 0,
    },
  ],
  faq: [
    {
      question: "Question 1",
      answer: "Answer 1",
      sequence: 0,
    },
    {
      question: "Question 2",
      answer: "Answer 2",
      sequence: 0,
    },
    {
      question: "Question 3",
      answer: "Answer 3",
      sequence: 0,
    },
    {
      question: "Question 4",
      answer: "Answer 4",
      sequence: 0,
    },
    {
      question: "Question 5",
      answer: "Answer 5",
      sequence: 0,
    },
  ],
};
