import { all, put, takeEvery } from "redux-saga/effects";
import api from "../../../utils/axios";
import {
  imageActionSuccess,
  imageActionFailure,
} from "../../actions/images/imagesActions";
import { ADDIMAGES } from "../../actions/types";

function* fetchImage(action) {
  try {
    let imagesApiData = yield api(process.env.REACT_APP_CMS_API).get(
      `get-all-images`
    );
    let imagesData = imagesApiData?.data?.responseBody?.data;
    yield put(imageActionSuccess(imagesData));
  } catch (error) {
    yield put(imageActionFailure(error));
  }
}

function* imageSaga() {
  yield takeEvery(ADDIMAGES, fetchImage);
}

export default function* rootSaga() {
  yield all([imageSaga()]);
}
