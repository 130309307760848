import React, { Suspense } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Loader from "../views/common/loader";
import { landingPageRouters } from "../views/landing/LandingRouter";
import { dashboardPageRouters } from "../views/dashboard/dashboardRouters";
import { myAccountPageRouters } from "../views/MyAccount/myAccountRouter";
import { emptyNavPageRouter } from "../views/EmptyNavComponents/emptyNavRouter";
import { loginAndRegisterPageRouters } from "../views/Register/RegisteRouter";
import { loginPageRouters } from "../views/Login/LoginRouter";
import { calculatorPageRouter } from "../views/calculator/calculatorRouter";
import { GoalImagesRouter } from "../views/dashboard/GraphImages/GoalImagesRouter";
import { errorPageRouters } from "../views/common/Error/ErrorRoutes";
import LoaderWrapper from "./loaderWrapper";
import ScrollToTop from "./scrollToTop";
import PrivateRoute from "./PrivateRoute";
// this is local routes needs to be placed at proper place once implmenation done
import { localRoutes } from "./localRoutes";
import { retiralPdfRoute } from "../views/RetiralPdf/retiralPdfRoute";
import { ssoRoutes } from "../views/SSO/ssoRouting";
import { corpRegisterRouting } from "../views/CorpRegister/corpRegisterRouting";

function WaitingComponent(Component) {
  return (props) => (
    <Suspense fallback={<Loader />}>
      <Component {...props} />
    </Suspense>
  );
}

export default function AppRouter({ ...props }) {
  //Added rerouting for corporate URLs
  const location = window.location.pathname;
  const pathname = location?.split("/");

  if (pathname.length && pathname[1] === "register") {
    window.location.href = process?.env?.REACT_APP_LIFE99 + location;
  }

  const error404 = React.lazy(() => import("../views/common/Error/Error404"));

  let allPublicRoutes = [
    ...localRoutes,
    ...landingPageRouters,
    ...loginAndRegisterPageRouters,
    ...loginPageRouters,
    ...GoalImagesRouter,
    ...errorPageRouters,
    ...corpRegisterRouting,
  ];

  let allPrivateRoutes = [
    ...calculatorPageRouter,
    ...myAccountPageRouters,
    ...emptyNavPageRouter,
    ...dashboardPageRouters,
    ...retiralPdfRoute,
  ];

  return (
    <div className="app-router">
      <LoaderWrapper>
        <div className="router-layout">
          <Router>
            <ScrollToTop>
              <Switch>
                {allPublicRoutes.map((value, index) => {
                  return (
                    <Route
                      exact={value.exact}
                      path={value.path}
                      component={WaitingComponent(value.component)}
                      key={index}
                    />
                  );
                })}

                {allPrivateRoutes.map((value, index) => {
                  return (
                    <PrivateRoute
                      exact={value.exact}
                      path={value.path}
                      component={WaitingComponent(value.component)}
                      key={index}
                    />
                  );
                })}

                <Route
                  path={ssoRoutes.path}
                  component={WaitingComponent(ssoRoutes.component)}
                  key={1}
                />

                <Route>{WaitingComponent(error404)}</Route>
              </Switch>
            </ScrollToTop>
          </Router>
        </div>
      </LoaderWrapper>
    </div>
  );
}
