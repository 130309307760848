import { lazy } from "react";
import { calculatorPaths } from "../../utils/RoutingConstants";

const RetirementCalculator = lazy(() => import("./retirementCalculator"));
const GoalPlanningCalculator = lazy(()=> import("./goalPlanningCalculator"))
const retirementCalculatorPaths = [
    calculatorPaths.retirmentCalculator,
    calculatorPaths.jumpToQuestion,
    calculatorPaths.retiralScore
];
const goalPlanningCalculator = [
  calculatorPaths.goalPlanningCalculator,
  calculatorPaths.goalPlanningJumpToQuestion
]
const retirementCalculatorPageRouter = retirementCalculatorPaths.map((ele) => ({
  path: ele,
  component: RetirementCalculator,
  exact: true,
}));

const goalPlanningCalculatorPageRouter = goalPlanningCalculator.map((ele) => ({
  path: ele,
  component: GoalPlanningCalculator,
  exact: true,
}));
export const calculatorPageRouter =[...retirementCalculatorPageRouter,...goalPlanningCalculatorPageRouter];
