import { lazy } from "react";
import { landingPagePaths } from "../../utils/RoutingConstants";
import SupportPagePC from "../EmptyNavComponents/supportPage/supportPagePc";

const LandingPage = lazy(() => import("./index"));

export const landingPageRouters = [
  {
    path: landingPagePaths.landingPage,
    component: LandingPage,
    exact: true,
  },
  {
    path: landingPagePaths.contactUsPage,
    component: SupportPagePC,
    exact: true,
  },
];
