import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "reactstrap";
import { useHistory } from "react-router-dom";

import Loading from "../../common/Loader/Loading";
import { supportConstants } from "../../../utils/constants.js";

import cloud from "../../../assets/images/Cloud/Cloud-1.svg";
import emailicon from "../../../assets/images/Email-open.svg";
import headset from "../../../assets/images/Headset.svg";
import Backarrow from "../../../assets/images/myaccount/backarrow.svg";
import phoneicon from "../../../assets/images/PhoneCall.svg";
import api from "../../../utils/axios";
import Faq from "../../landing/FAQ/Faq";
import "../../landing/FAQ/faqs.scss";
import { fullPageData } from "../../landing/fullPage";
import "./support.scss";

const SupportPagePC = () => {
  const history = useHistory();

  const [landingCmsData, setLandingCmsData] = useState({});
  const [pageLoad, setPageLoad] = useState(false);
  // fetching data for cms
  const fecthLandingCmsData = async () => {
    let cmsData;
    try {
      let landingPageApi = await api(process.env.REACT_APP_CMS_API).get(
        "landing"
      );
      cmsData = landingPageApi.data.responseBody.data;
    } catch (e) {
      cmsData = fullPageData;
    }
    setLandingCmsData(cmsData);
  };

  useEffect(() => {
    const handleFunctionCall = async () => {
      await setPageLoad(true);
    };
    handleFunctionCall();
  }, [pageLoad]);

  useEffect(() => {
    fecthLandingCmsData();
  }, []);

  return (
    <>
      {pageLoad ? (
        <>
          <div className="support-page-pc pt-2">
            <Container>
              <Row className=" m-0 back-section">
                <Col sm={12}>
                  <div className="d-flex">
                    <img
                      src={Backarrow}
                      alt="Backarrow"
                      title="Backarrow"
                      className="Backarrow"
                      onClick={() => history.goBack()}
                    />
                    <p className="mb-0">Support</p>
                  </div>
                </Col>
              </Row>
            </Container>
            <div className="container hero-bg">
              <div className="hero-data">
                <img src={headset} alt=""></img>
                <h2>How can we help you?</h2>
                <div className="card-base-grid mt-3">
                  <div className="card-item">
                    <div className="icon-box">
                      <img src={emailicon} alt=""></img>
                    </div>
                    <div className="text-group">
                      <span>Send us a email</span>
                      <a href={`mailto:${supportConstants?.mailId}`}>
                        <p>{supportConstants?.mailId}</p>
                      </a>
                    </div>
                  </div>
                  <div className="card-item">
                    <div className="icon-box">
                      <img src={phoneicon} alt=""></img>
                    </div>
                    <div className="text-group">
                      <span>Reach out to us on</span>
                      <a href={`tel:${supportConstants?.mobile}`}>
                        {" "}
                        <p>{supportConstants?.mobile}</p>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="cloud-bg">
                <img className="cloud-O" src={cloud} alt=""></img>
                <img className="cloud-T" src={cloud} alt=""></img>
                <img className="cloud-Tr" src={cloud} alt=""></img>
                <img className="cloud-F" src={cloud} alt=""></img>
              </div>
            </div>

            <Faq faqSection={landingCmsData?.faq} />
          </div>
        </>
      ) : (
        <Loading />
      )}{" "}
    </>
  );
};

export default SupportPagePC;
