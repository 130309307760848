import aesjs from "aes-js";
import { storageFactory } from "storage-factory";
import api from "./axios";
import { EncryptData } from "../utils/encryptedPayload";
import crypto from "crypto";
import base64url from "base64url";
const CryptoJS = require("crypto-js");

const paddSpaces = (password) => {
  while (password.length < 16) {
    password = decodeURIComponent(process.env.REACT_APP_PADDSPACES) + password;
  }
  return password;
};

export const encryptPassword = (password) => {
  if (password && password.length > 0) {
    const key = JSON.parse(process.env.REACT_APP_ENCRYPTION_KEY);
    const iv = JSON.parse(process.env.REACT_APP_ENCRYPTION_IV);
    const text = password.length < 16 ? paddSpaces(password) : password; // if password < 16 Bytes then paddSpaces
    const textBytes = aesjs.utils.utf8.toBytes(text);
    const aesCbc = new aesjs.ModeOfOperation.cbc(key, iv);
    const encryptedBytes = aesCbc.encrypt(textBytes);
    const encryptedHex = aesjs.utils.hex.fromBytes(encryptedBytes);
    return encryptedHex;
  } else {
    return "";
  }
};

export const encrypeRequestBody = (jsonData) => {
  const jsonString = JSON.stringify(jsonData);
  const secretKey = process.env.REACT_APP_ENCRYPTION_KEY;

  // Encrypt the JSON data
  const encryptedData = CryptoJS.AES.encrypt(jsonString, secretKey).toString();

  return encryptedData;
};
const IV_LENGTH = 12;
export const encryptAesGCM = (data, encryptionKey) => {
  const plainText = typeof data === "string" ? data : JSON.stringify(data);
  const iv = crypto.randomBytes(IV_LENGTH);
  const cipher = crypto.createCipheriv(
    process.env.REACT_APP_DB_FIELD_ENCRYPT_ALGO,
    process.env.REACT_APP_DB_FIELD_ENCRYPT_KEY,
    iv
  );
  let cipherText = cipher.update(plainText, "utf-8");
  cipherText = Buffer.concat([cipherText, cipher.final()]);
  const tag = cipher.getAuthTag();
  const encryptedData = Buffer.concat([iv, cipherText, tag]);
  return base64url(encryptedData);
};

export const isLogin = () => {
  let token = localStorage.getItem("loginToken");
  let checkLogin = false;
  if (token !== undefined && token !== null) {
    checkLogin = true;
  }
  return checkLogin;
};

const baseURL = process.env.REACT_APP_API_DOMAIN;
export const logOut = () => {
  if (window.flutter_inappwebview) {
    window.flutter_inappwebview
      .callHandler("logout")
      .then((isSuccessful) => {
        console.log("logout");
      })
      .catch((error) => {
        console.log(error);
      });
  } else {
    const token = localStorage.getItem("loginToken");

    api(`${baseURL}/api-mdm/auth/logout`, token)
      .get()
      .then((res) => {
        if (res.status === 200) {
          localStorage.clear();
          window.location.href = "/";
        }
      })
      .catch((err) => {
        console.error(err);
        localStorage.clear();
        window.location.href = "/";
      });
  }
};
export const local = storageFactory(() => localStorage);

export const appendToken = () => {
  // let token = JSON.parse(local.getItem("individualInfo")).ssoUrlToken
  let loginToken = local.getItem("loginToken");
  return "?token=" + loginToken;
};

export const ageFromDOB = (dob) => {
  let ageDiffrence = new Date() - new Date(dob);
  let ageCurrent = new Date(ageDiffrence);
  let currentUserAge = Math.abs(ageCurrent.getUTCFullYear() - 1970);
  return currentUserAge;
};

export const yearValidation = (value) => {
  return "Years";
};

export const isNumber = (value) => {
  if (isNaN(value)) {
    value = 0;
  }
};

export const numToValue = (value) => {
  let checkVal = value < 0 ? "-" : "";
  let val = Math.abs(value);
  if (val >= 10000000) {
    val = (val / 10000000).toFixed(2) + " Crs";
  } else if (val >= 100000) {
    val = (val / 100000).toFixed(2) + " L";
  } else if (val >= 1000) {
    val = (val / 1000).toFixed(2) + " K";
  } else if (val >= 100) {
    val = (val / 100).toFixed(2) + "";
  } else if (isNaN(val)) {
    val = 0;
  } else {
    val = val + "";
  }
  return `${checkVal}${val}`;
};

export const convertRetiralScore = (value) => {
  if (isNaN(value) || value <= 0) {
    return `0%`;
  }
  if (value >= 500) {
    return `500%+`;
  } else {
    return `${Math.round(value)}%`;
  }
};

export const redirectToUrl = (link, delay) => {
  let linkBreaked = link.split(".");
  if (
    linkBreaked.includes("life99") ||
    linkBreaked.includes("https://life99")
  ) {
    setTimeout(() => {
      const individualInfo = localStorage.getItem("individualInfo");
      const authToken = localStorage.getItem("loginToken");
      let encryptedParams = EncryptData({
        target: link,
        token: authToken,
        individualInfo: JSON.parse(individualInfo),
        mvpLogin: true,
      });
      const url = `${link}?token=${encryptedParams}`;
      window.location.href = url;
    }, delay);
  } else {
    window.location.href = link;
  }
};

export const redirectLink = (link) => {
  const url = window.open(link);
  url.opener = null;
};

export const GoogleAnalystic = (action, category, event, url) => {
  window.dataLayer.push({
    action: action,
    category: category,
    event: event,
    url: url,
  });
};

export const mapCheck = (value) => {
  if (Array.isArray(value) && value?.length > 0) {
    return true;
  } else {
    return false;
  }
};

export const handleSanchayPlusRedirection = async () => {
  let token = local.getItem("loginToken");
  let individuelInfo = JSON.parse(local.getItem("individualInfo"));
  let individualId = individuelInfo.individual_id;
  let SANCHAY_PLUS_BUY = process.env.REACT_APP_SANCHAY_PLUS_URL;
  let sanchayData;
  api(baseURL, token)
    .get("api-mdm/sanchay-plus/get-sanchay-policy-status")
    .then((res) => {
      const { status } = res?.data?.data;
      if (status) {
        window.location.href = res?.data?.data?.redirectUrl;
      } else {
        window.location.href = `${SANCHAY_PLUS_BUY}&partnerUserId=${individualId}`;
      }
    });

  return sanchayData;
};
