import { all, put, takeEvery } from "redux-saga/effects";
import api from "../../../utils/axios";
import {
  addArticleSuccess,
  addArticleFailure,
} from "../../actions/learn/LearnActions";
import { articleUrl } from "../../../utils/constants";

import { ADDARTICLE } from "../../actions/types";

function handleArticle(data) {
  const articleTemp = {
    all: [],
    retirement: [],
    investment: [],
    taxPlanning: [],
    inheritance: [],
    healthWellness: [],
  };
  Object.entries(data).map(([key, value]) => {
    let articleObj = value.article_data;
    switch (value.type) {
      case "retirement":
        articleTemp["retirement"].push(articleObj);
        break;
      case "investment":
        articleTemp["investment"].push(articleObj);
        break;
      case "taxPlanning":
        articleTemp["taxPlanning"].push(articleObj);
        break;
      case "inheritance":
        articleTemp["inheritance"].push(articleObj);
        break;
      case "healthWellness":
        articleTemp["healthWellness"].push(articleObj);
        break;
      default:
        break;
    }
    articleTemp["all"].push(articleObj);
    return "";
  });
  return articleTemp;
}

function* fetchArticle(action) {
  try {
    let articleApiData = yield api(articleUrl).get(
      `/knowledge-center/get-articles/`
    );
    let articleData = articleApiData.data;
    let categoryArticleData = yield handleArticle(articleData);
    yield put(addArticleSuccess(categoryArticleData));
  } catch (error) {
    yield put(addArticleFailure(error));
  }
}

function* articalSaga() {
  yield takeEvery(ADDARTICLE, fetchArticle);
}

export default function* rootSaga() {
  yield all([articalSaga()]);
}
