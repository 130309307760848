import { ADDIMAGES, ADDIMAGESSUCCESS, ADDIMAGESFAILURE } from "../types";

export const imageAction = (data) => ({
  type: ADDIMAGES,
  payLoad: data,
});

export const imageActionSuccess = (data) => ({
  type: ADDIMAGESSUCCESS,
  payLoad: data,
});

export const imageActionFailure = (data) => ({
  type: ADDIMAGESFAILURE,
  payLoad: data,
});
