export const pdfStyleObj = {
  //New Design
  page: {
    backgroundColor: "#fff",
    fontFamily: "Poppins",
    height: "400px",
    padding: "20px 40px",
  },
  PDFViewer: {
    width: "100vw",
    height: "100vh",
  },
  headlogo: {
    // paddingBottom: 25,
    width: "100%",
    height: "70px",
  },
  logo: {
    marginLeft: "auto",
    width: "78px",
    height: "51px",
  },
  notelink: {
    fontSize: "10px",
  },
  RUsign: { fontFamily: "Inter" },
  margintop: { marginTop: "auto" },
  cardmain: {
    height: "100%",
  },
  // ----------------------------page-1------------------
  aboutuser: {
    display: "block",
  },
  DMtext: {
    fontFamily: "Satisfy",
    fontSize: "20px",
    color: "#005E9E",
    marginBottom: "17px",
    marginTop: "10px",
  },
  abouttext: {
    fontSize: "12px",
    color: "#005E9E",
    marginTop: "17px",
    marginRight: "43%",
  },
  Distext: {
    fontSize: "12px",
    color: "#005E9E",
    marginTop: "17px",
  },
  homeill: {
    position: "absolute",
    top: "-240px",
    right: "-40px",
    width: "274px",
  },
  homefootertext: {
    fontSize: "14px",
    color: "#000",
    marginTop: "17px",
  },
  homefooterbold: {
    fontSize: "14px",
    color: "#00C0F3",
    marginTop: "17px",
    fontWeight: "600",
  },
  graph: {
    marginTop: 10,
    // display: "flex",
    // flexDirection: "row",
    // alignItems: "center",
    // justifyContent: "space-between",
  },
  HomegraphLHS: { width: "100%" },
  /// color box css
  "National Pension System": {
    backgroundColor: "#005E9E",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  "Public Provident Fund": {
    backgroundColor: "#D1933A",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  Annuity: {
    backgroundColor: "#92E3A9",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  "Fixed deposit": {
    backgroundColor: "#7B5AA6",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  "Post office schemes": {
    backgroundColor: "#F17C7C",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  "Mutual Funds": {
    backgroundColor: "#4AAAC1",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  "Sukanya Samriddhi Yojana": {
    backgroundColor: "#DFA618",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  "Superannuation Fund": {
    backgroundColor: "#00c0f3",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  Equity: {
    backgroundColor: "#4AAAC1",
    width: "15px",
    height: "15px",
    borderRadius: "50%",
    marginRight: "15px",
  },
  Gold: {
    backgroundColor: "#DFA618",
    width: "15px",
    height: "15px",
    borderRadius: "40%",
    marginRight: "15px",
  },
  "Employee Provident Fund": {
    backgroundColor: "#3268a8",
    width: "15px",
    height: "15px",
    borderRadius: "40%",
    marginRight: "15px",
  },
  Gratuity: {
    backgroundColor: "#a8b6c0",
    width: "15px",
    height: "15px",
    borderRadius: "40%",
    marginRight: "15px",
  },
  "Real Estate": {
    backgroundColor: "#ee6b3b",
    width: "15px",
    height: "15px",
    borderRadius: "40%",
    marginRight: "15px",
  },
  "Group Health Insurance": {
    backgroundColor: "#34495e",
    width: "15px",
    height: "15px",
    borderRadius: "40%",
    marginRight: "15px",
  },
  "Individual Health Insurance": {
    backgroundColor: "#9b59b6",
    width: "15px",
    height: "15px",
    borderRadius: "40%",
    marginRight: "15px",
  },
  "Term Deposit": {
    backgroundColor: "#2ecc71",
    width: "15px",
    height: "15px",
    borderRadius: "40%",
    marginRight: "15px",
  },
  RetiralScoreHeadText: {
    fontSize: "26px",
    color: "#005E9E",
  },
  gridgraph: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    margin: "20px 0",
  },
  gridgraphitm: {
    width: "50%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "30px",
    marginBottom: "16px",
  },
  graphval: {
    fontSize: "15px",
    color: "#000",
    marginTop: "17px",
    fontFamily: "Inter",
    marginBottom: "17px",
  },
  homegraph: {
    width: "100%",
    height: "200px",
  },
  AddUpdateBtn: {
    backgroundColor: "#1A3E6D",
    color: "#fff",
    textDecoration: "none",
    width: "150px",
    fontSize: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 0",
    borderRadius: "99px",
  },
  graphfooterdiv: {
    // width: "500px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  graphfootertext: {
    // marginLeft: 175,
    fontSize: "12px",
    color: "#403F3F",
    fontWeight: "400",
    marginTop: "20px",
  },
  homefooterborder: {
    borderTop: "1px solid #979797",
    marginTop: "auto",
    marginBottom: "75px",
  },
  // ----------------------------page-2------------------
  ProductheadDiv: {},
  Producthead: {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "space-between",
  },
  Productheading: {
    fontSize: "18px",
    color: "#005E9E",
    fontWeight: "600",
    marginTop: "10px",
  },
  Productext: {
    fontSize: "14px",
    color: "#005E9E",
    fontWeight: "600",
    width: "200px",
    marginBottom: 10,
    marginTop: 10,
  },
  productill: {
    width: "100px",
    marginLeft: 50,
  },
  ProductCardBase: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  ProductCard: {
    width: "250px",
    padding: "20px",
    borderRadius: "8px",
    backgroundColor: "#ECF7FF",
    marginTop: "25px",
    height: "160px",
  },
  ProductCardHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start",
    marginBottom: "30px",
  },
  ProductNI: {
    maxWidth: "130px",
    flex: "1",
  },
  Producticon: {
    width: "20px",
  },
  ProductName: {
    fontSize: "14px",
    color: "#005E9E",
    fontWeight: "600",
  },
  AddUpdateBtnProduct: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    color: "#FFFFFF",
    fontSize: "10px",
    textDecoration: "none",
    backgroundColor: "#1A3E6D",
    padding: "5px 10px",
    borderRadius: "99px",
    width: "60px",
  },
  ProductCorpusdata: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: "auto",
  },
  ProductCorpustitle: {
    fontSize: "10px",
    color: "#687A86",
  },
  ProductCorpusMoney: {
    fontSize: "14px",
    color: "#005E9E",
    fontWeight: "600",
  },
  ProductCorpusMoneyLi: {
    fontSize: "14px",
    color: "#0BB8E4",
    fontWeight: "600",
  },

  // ----------------------------page-3------------------
  ProductFootertext: {
    fontSize: "10px",
    color: "#403F3F",
    fontWeight: "400",
    marginTop: "10px",
    fontFamily: "PublicSansItalic",
  },
  InvestProductDataBase: {
    backgroundColor: "#ECF7FF",
    borderRadius: "8px",
    marginTop: "20px",
  },
  InvestProductDataHead: {
    fontSize: "18px",
    color: "#005E9E",
    fontWeight: "600",
  },
  InvestProductDataHeadText: {
    fontSize: "14px",
    color: "#263238",
    fontWeight: "600",
  },
  InvestProductbase: {
    padding: "10px 20px 30px 30px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  InvestProductgrid: {
    width: "25%",
    marginTop: "20px",
  },
  InvestProductname: {
    fontSize: "11px",
    color: "#363D4E",
    width: "98%",
  },
  InvestProductmoney: {
    fontSize: "11px",
    color: "#363D4E",
  },

  // ----------------------------page-4----------------
  goalHeadBox: {
    // backgroundColor:"#000",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0 0 22px 0px",
    position: "relative",
  },
  goalHeadtext: {
    fontSize: "20px",
    color: "#005E9E",
    width: "50%",
  },
  goalHeadill: {
    position: "absolute",
    right: "0px",
    width: "250px",
  },
  goalgraphHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 80,
  },
  goalgraphHeadtext: {
    fontSize: "14px",
    color: "#005E9E",
    marginTop: 40,
    width: "42%",
  },
  goalgraphimg: {
    height: "400px",
  },
  goalgraph: {
    height: "350px",
    backgroundColor: "#ECF7FF",
    borderRadius: "20px",
    marginTop: "50",
    padding: "30px 20px",
  },
  goalgraphrow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "10px 0",
    borderTop: "1px solid #dadada",
    borderBottom: "1px solid #dadada",
  },
  goalgraphcol: {
    fontSize: "14px",
    color: "#005E9E",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },

  goalgraphrowval: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    padding: "10px 0",
    borderBottom: "1px solid #dadada",
  },
  goalgraphcolval: {
    fontSize: "11px",
    color: "#403F3F",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
  },
  goalgraphcta: {
    marginTop: 40,
    width: "100%",
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "flex-end",
    flexDirection: "column",
  },
  goalgraphctatext: { fontSize: "12px", color: "#403F3F" },
  goalgraphctaBtn: {
    backgroundColor: "#1A3E6D",
    color: "#fff",
    textDecoration: "none",
    width: "140px",
    fontSize: "10px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    padding: "8px 0",
    borderRadius: "99px",
    marginTop: 10,
  },
  // ----------------------------page-5----------------
  NeededHead: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "10",
  },
  NeededHeadtext: {
    fontSize: "20px",
    color: "#005E9E",
    width: "80%",
  },
  RetiralScoreBox: {
    display: "flex",
    justifyContent: "flex-start",
  },
  RetiralScoreBoxFY: {
    width: "50%",
    display: "flex",
    flexDirection: "",
    justifyContent: "center",
    alignItems: "center",
  },
  RetiralScoreText: { fontSize: "12px", color: "#005E9E" },
  RetiralScorevla: { fontSize: "26px", color: "#005E9E" },
  summarycard: {
    padding: "15px 0px",
    borderTop: "1px solid #DADADA",
  },
  summarycardhead: {
    fontSize: "20px",
    color: "#005E9E",
  },
  summarycardREgrid: {
    display: "flex",
    flexDirection: "row",
    gap: "4px",
  },
  summarycardREtext: {
    fontSize: "16px",
    color: "#005E9E",
  },
  summarycardRE: {
    fontSize: "14px",
    color: "#005E9E",
  },
  summarycardproducthead: {
    fontSize: "16px",
    color: "#000",
    marginTop: 0,
    fontFamily: "PublicSansItalic",
  },
  summarycardproductitems: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "90%",
    marginTop: 8,
  },
  summarycardproducname: {
    fontSize: "14px",
    color: "#000",
  },
  summarycardproductag: {
    fontSize: "14px",
    color: "#2277F1",
    padding: "3px 10px 3px 10px",
    borderRadius: "4px",
    backgroundColor: "#2277F126",
  },

  // ----------------------------page-6----------------
  summarycardNOborder: {
    padding: "15px 0px",
  },
  RetiralAdvisorbase: {
    marginTop: "auto",
    marginBottom: 20,
    padding: "20 0",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTop: "3px dashed #9CB7DA",
    borderBottom: "3px dashed #9CB7DA",
  },
  RetiralAdvisorData: {
    width: "70%",
  },
  RetiralAdvisortext: {
    fontSize: "12px",
    color: "#263238",
    marginBottom: 10,
  },
  RetiralAdvisortextEC: {
    marginTop: 10,
    fontSize: "12px",
    color: "#263238",
  },
  RetiralAdvisorBTN: {
    backgroundColor: "#005E9E",
    borderRadius: "99px",
    fontSize: "12px",
    color: "#fff",
    padding: "10px 20px",
    marginTop: 30,
    textDecoration: "none",
    width: "130px",
  },
  RetiralAdvisorimg: {
    width: "80px",
  },
  AppDownload: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "20",
  },
  AppDownloadText: {
    fontSize: "26px",
    color: "#000",
  },
  AppDownloadbtngroup: {
    display: "flex",
    flexDirection: "row",
    marginTop: "10",
  },
  AppDownloadBTN: {
    width: "80px",
  },
  AppDownloadBTNP: {
    width: "80px",
    marginLeft: 10,
  },
  mobilemockup: {
    width: "200px",
  },
  InterBold: {
    fontFamily: "InterBold",
  },
  PublicSansItalic: {
    fontFamily: "PublicSansItalic",
    fontSize: "11px",
  },

  PDFContainer: {
    textDecoration: "none",
  },
};
