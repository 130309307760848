import {
  ADDUSEDINVEST,
  ADDNONUSEDINVEST,
  ADDDASHBORADCMS,
  ADDCALCULATIONDATA,
  ADDUSEDINVESTFAILURE,
  ADDDASHBORADCMSSUCCESS,
  ADDDASHBORADCMSFAILURE,
  ADDCALCULATIONDATASUCCESS,
  ADDCALCULATIONDATAFAILURE,
  REFERRALFLAG,
} from "../types";

export const investInsActionSuccess = (data) => ({
  type: ADDUSEDINVEST,
  payLoad: data,
});

export const investInsActionFailure = (data) => ({
  type: ADDUSEDINVESTFAILURE,
  payLoad: data,
});

export const investNonUsedInsDataAction = (data) => ({
  type: ADDNONUSEDINVEST,
  payLoad: data,
});

// dashboard cms ==============================================================

export const dashboardCms = () => ({
  type: ADDDASHBORADCMS,
  payLoad: {},
});

export const dashboardCmsSuccess = (data) => ({
  type: ADDDASHBORADCMSSUCCESS,
  payLoad: data,
});

export const dashboardCmsFailure = (data) => ({
  type: ADDDASHBORADCMSFAILURE,
  payLoad: data,
});
//  retrial info ==================================================================
export const calcualtionData = (data) => ({
  type: ADDCALCULATIONDATA,
  payLoad: data,
});

export const calcualtionSuccess = (data) => ({
  type: ADDCALCULATIONDATASUCCESS,
  payLoad: data,
});

export const calcualtionFailure = (data) => ({
  type: ADDCALCULATIONDATAFAILURE,
  payLoad: data,
});

//  retrial info ==================================================================
export const referralFlag = (data) => ({
  type: REFERRALFLAG,
  payLoad: data,
});
// ==================================================================================
