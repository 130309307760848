export const ADDUSEDINVEST = "ADD_INVEST";
export const ADDUSEDINVESTSUCCESS = "ADD_INVEST_SUCCESS";
export const ADDUSEDINVESTFAILURE = "ADD_INVEST_FAILURE";
export const ADDUSEDINVESTCALL = "ADD_INVEST_FETCH";

export const ADDNONUSEDINVEST = "ADD_NON_INVEST";
export const ADDNONUSEDINVESTSUCCESS = "ADD_NON_INVEST_SUCCESS";
export const ADDNONUSEDINVESTFAILURE = "ADD_NON_INVEST_FAILURE";

// dashboard cms ===========================================================================
export const ADDDASHBORADCMS = "ADD_DASHBORAD_CMS";
export const ADDDASHBORADCMSSUCCESS = "ADD_DASHBORAD_CMS_SUCCESS";
export const ADDDASHBORADCMSFAILURE = "ADD_DASHBORAD_CMS_FAILURE";

// calculation data ========================================================================
export const ADDCALCULATIONDATA = "ADD_CALCULATION_DATA";
export const ADDCALCULATIONDATASUCCESS = "ADD_CALCULATION_DATA_SUCCESS";
export const ADDCALCULATIONDATAFAILURE = "ADD_CALCULATION_DATA_FAILURE";

// Leran data ===============================================================================

export const ADDARTICLE = "ADD_ARTICLE";
export const ADDARTCILESUCCESS = "ADD_ARTICLE_SUCCESS";
export const ADDARTICLEFAILURE = "ADD_ARTICLE_FAILURE";

// Plan data ================================================================================

export const PLANGOALS = "ADD_GOAL";

// image from api ============================================================================

export const ADDIMAGES = "ADD_IMAGE";
export const ADDIMAGESSUCCESS = "ADD_IMAGE_SUCCESS";
export const ADDIMAGESFAILURE = "ADD_IMAGE_FAILURE";

export const REMOVEALLDATA = "REMOVE_ALL_DATA";

// Referral flag ============================================================================

export const REFERRALFLAG = "REFERRALFLAG";
