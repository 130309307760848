import crypto from "crypto";

export function EncryptData(dataObj) {
  let ENC_KEY = process.env.REACT_APP_PAYLOAD_ENCRYPTION_KEY;
  const iv = crypto.randomBytes(12);
  const cipher = crypto.createCipheriv("aes-256-gcm", ENC_KEY, iv);
  let cipherText = cipher.update(JSON.stringify(dataObj), "utf-8");
  cipherText = Buffer.concat([cipherText, cipher.final()]);
  const tag = cipher.getAuthTag();
  const encryptedData = Buffer.concat([iv, cipherText, tag]);
  return encryptedData.toString("base64");
}

// export function DecryptData(encryptedString){
//     let ENC_KEY = process.env.REACT_APP_PAYLOAD_ENCRYPTION_KEY
//     const encryptedBuffer = Buffer.from(encryptedString, 'base64');
//     const iv = encryptedBuffer.slice(0, 12);
//     const cipherText = encryptedBuffer.slice(12, -16);
//     const tag = encryptedBuffer.slice(-16);
//     const decipher = crypto.createDecipheriv('aes-256-gcm', ENC_KEY, iv);

//     decipher.setAuthTag(tag);
//     let data = decipher.update(cipherText, 'base64', 'utf8');
//     data += decipher.final('utf8');
//     return typeof data === 'string' ? JSON.parse(data) : data;
// }

export const DecryptData = (encryptedString) => {
  let tokenGrabbed = encryptedString.toString().split("?")[1];
  let params = new URLSearchParams(tokenGrabbed);
  let getAllToken = params.getAll("token");
  let exactToken = getAllToken[0].split(" ").join("+");
  const encryptedBuffer = Buffer.from(exactToken, "base64");
  const iv = encryptedBuffer.slice(0, 12);
  const cipherText = encryptedBuffer.slice(12, -16);
  const tag = encryptedBuffer.slice(-16);
  const decipher = crypto.createDecipheriv(
    "aes-256-gcm",
    process.env.REACT_APP_PAYLOAD_ENCRYPTION_KEY,
    iv
  );
  decipher.setAuthTag(tag);
  let data = decipher.update(cipherText, "base64", "utf8");
  data += decipher.final("utf8");
  return typeof data === "string" ? JSON.parse(data) : data;
};
