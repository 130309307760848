import { lazy } from "react";
import { dashboardPaths } from "../../utils/RoutingConstants";
const dashboardPage = lazy(() => import("./index"));
const paths = [
  dashboardPaths.dashboard,
  dashboardPaths.invest,
  dashboardPaths.wellbeing,
  dashboardPaths.wellbeingTaxService,
  dashboardPaths.wellbeingWillPlanning,
  dashboardPaths.wellbeingHealthWellness,
  dashboardPaths.wellbeingCommunity,
  dashboardPaths.plan,
  dashboardPaths.offerdetail,
  dashboardPaths.nps,
  dashboardPaths.annuity,
  dashboardPaths.advisory,
  dashboardPaths.learn,
  dashboardPaths.portfolio,
];
export const dashboardPageRouters = paths.map((ele) => ({
  path: ele,
  component: dashboardPage,
  exact: true,
}));
