import {
  ADDARTCILESUCCESS,
  ADDARTICLEFAILURE,
} from "../../actions/types/index";

const learnData = {
  article: {},
};

const learnReducer = (state = learnData, action) => {
  switch (action.type) {
    case ADDARTCILESUCCESS:
      return {
        ...state,
        article: action.payLoad,
      };

    case ADDARTICLEFAILURE:
      return {
        ...state,
        article: {},
      };

    default:
      return state;
  }
};

export default learnReducer;
