import { lazy } from "react";
import { errorPaths } from "../../../utils/RoutingConstants";

const Error404 = lazy(() => import("./Error404"));
const InternetError = lazy(() => import("./InternetError"));

export const errorPageRouters = [
  {
    path: errorPaths.fourZeroFour,
    component: Error404,
    exact: true,
  },
  {
    path: errorPaths.internetError,
    component: InternetError,
    exact: true,
  },
];
