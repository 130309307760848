import { lazy } from "react";
import { graphImages } from "../../../utils/RoutingConstants";

const goalImg = lazy(() => import("./PlanGraph"));
const retialImg = lazy(() => import("./RetiralGraph"));
const expenseImg = lazy(() => import("./expenseGraph"));

const paths = [
  graphImages.planGoalGraph,
  graphImages.retiralScoreGraph,
  graphImages.expenseCoinGraph,
];
const comp = [goalImg, retialImg, expenseImg];

export const GoalImagesRouter = paths.map((ele, i) => ({
  path: ele,
  component: comp[i],
  exact: true,
}));
