export const landingPagePaths = {
  landingPage: "/",
  contactUsPage: "/portal/employee/contactUsPage",
};
export const corpRegisterPath = {
  corpRegisterPage: "/register/*",
  registerPage: "/register",
};
export const LoginAndRegisterPagePaths = {
  loginPage: "/portal/onboarding/login",
  forgetPassword: "/portal/onboarding/forgetpassword",
  RegisterPage: "/portal/register",
  verifyOtp: "/portal/register/verifyOtp",
  setPassword: "/portal/register/setPassword",
  UpdatePassword: "/portal/onboarding/password/updatePassword",
  forgotPassword: "/portal/onboarding/password/forgotPassword",
  successPage: "/portal/successPage",
  Summary: "/portal/summary",
  legalInfo: "/portal/employee/legalInfo",
  prelogin: "/portal/retiralCalculator",
};
export const dashboardPaths = {
  dashboard: "/portal/employee/dashboard",
  invest: "/portal/employee/invest",
  plan: "/portal/employee/plan",
  advisory: "/portal/employee/advisory",
  learn: "/portal/employee/learn",
  wellbeing: "/portal/employee/wellbeing",
  wellbeingTaxService: "/portal/employee/wellbeing/tax-service",
  wellbeingWillPlanning: "/portal/employee/wellbeing/will-planning",
  wellbeingHealthWellness: "/portal/employee/wellbeing/health-wellness",
  wellbeingCommunity: "/portal/employee/wellbeing/community",
  offerdetail: "/portal/employee/offerdetail",
  nps: "/portal/employee/nps",
  annuity: "/portal/employee/annuity",
  portfolio: "/portal/employee/portfolio",
};

export const myProfilePaths = {
  myProfile: "/portal/employee/profile",
  personalDetails: "/portal/employee/profile/personal-details",
  kycDetails: "/portal/employee/profile/kyc-details",
  bankDetails: "/portal/employee/profile/bank-details",
  contactDetails: "/portal/employee/profile/contact-details",
  nominee: "/portal/employee/profile/nominee",
  referFriend: "/portal/employee/profile/referFriend",
  preApprovedInsurance: "/portal/employee/profile/preApprovedInsurance",
  sidebarMobile: "/portal/employee/profile/sidebar-mobile",
  corporateDetails: "/portal/employee/profile/corporate-details",
  transaction: "/portal/employee/profile/transaction",
  linkCorporateMV: "/portal/employee/profile/linkCorporateMV",
  verifyEmail: "/portal/employee/profile/verifyEmail",
};

export const emptyNavPaths = {
  offerDetails: "/portal/employee/offerDetails",
  calculator: "/portal/employee/calculators",
  verifyAccount: "/portal/employee/verifyAccount",
  serviceCorner: "/portal/employee/serviceCorner",
  webinars: "/portal/employee/webinar",
  legalInfo: "/portal/employee/legalInfo",
  supportPage: "/portal/employee/supportPage",
  community: "/portal/employee/community",
  article: "/portal/employee/article",
  webinarDetail: "/portal/employee/webinar-details",
  notificationMV: "/portal/employee/notification-mobile",
  JoinPlan: "/portal/employee/join-plan",
  linkInvestment: "/portal/employee/linkInvestment",
  podcast: "/portal/employee/podcast",
};

export const calculatorPaths = {
  retirmentCalculator: "/portal/employee/calculators/retirement",
  jumpToQuestion: "/retirementCalculator/jumpToQuestion",
  goalPlanningCalculator: "/goalPlanningCalculator",
  goalPlanningJumpToQuestion: "/goalPlanningCalculator/jumpToQuestion",
  retiralScore: "/retiralScore",
};
export const errorPaths = {
  fourZeroFour: "/portal/employee/404",
  internetError: "/portal/employee/internetError",
};

export const graphImages = {
  planGoalGraph: "/goal-plan",
  retiralScoreGraph: "/retiral-graph",
  expenseCoinGraph: "/expense-graph",
};

export const advisoryReportPaths = {
  createReport: "/create-report",
};

export const RetiralPdfPath = {
  genratePdf: "/genrate-pdf",
  genratePortfolio: "/genrate-portfolio",
};

export const SSOLoginPath = {
  ssoLogin: "/portal/employee/sso-login",
};
