import CorpRegister from "./index";
import { corpRegisterPath } from "../../utils/RoutingConstants";

export const corpRegisterRouting = [
  {
    path: corpRegisterPath.corpRegisterPage,
    component: CorpRegister,
    exact: true,
  },
  {
    path: corpRegisterPath.registerPage,
    component: CorpRegister,
    exact: true,
  },
];
