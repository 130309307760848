import { lazy } from "react";
import { LoginAndRegisterPagePaths } from "../../utils/RoutingConstants";
const SignupPage = lazy(() => import("./index"));
const Summary = lazy(() => import("./Summary/index"));
const legalInfo = lazy(() => import("../EmptyNavComponents/legalInfo"));

export const loginAndRegisterPageRouters = [
  {
    path: LoginAndRegisterPagePaths.RegisterPage,
    component: SignupPage,
    exact: false,
  },
  {
    path: LoginAndRegisterPagePaths.Summary,
    component: Summary,
    exact: true,
  },
  {
    path: LoginAndRegisterPagePaths.legalInfo,
    component: legalInfo,
    exact: true,
  },
];
