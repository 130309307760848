import { lazy } from "react";
import { myProfilePaths } from "../../utils/RoutingConstants";
const myAccountPage = lazy(() => import("./index"));
const routes = [
  myProfilePaths.myProfile,
  myProfilePaths.personalDetails,
  myProfilePaths.kycDetails,
  myProfilePaths.bankDetails,
  myProfilePaths.contactDetails,
  myProfilePaths.nominee,
  myProfilePaths.referFriend,
  myProfilePaths.preApprovedInsurance,
  myProfilePaths.corporateDetails,
  myProfilePaths.sidebarMobile,
  myProfilePaths.transaction,
  myProfilePaths.linkCorporateMV,
  myProfilePaths.verifyEmail,
];
export const myAccountPageRouters = routes.map((ele) => ({
  path: ele,
  component: myAccountPage,
  exact: true,
}));
