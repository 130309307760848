import { PLANGOALS } from "../../actions/types/index";

const planData = {
  goals: {},
};

const planGoalReducer = (state = planData, action) => {
  switch (action.type) {
    case PLANGOALS:
      return {
        ...state,
        goals: action.payLoad,
      };

    default:
      return state;
  }
};

export default planGoalReducer;
