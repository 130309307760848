import { ADDARTICLE, ADDARTCILESUCCESS, ADDARTICLEFAILURE } from "../types";

export const addArticle = () => ({
  type: ADDARTICLE,
  payLoad: {},
});

export const addArticleSuccess = (data) => ({
  type: ADDARTCILESUCCESS,
  payLoad: data,
});

export const addArticleFailure = (data) => ({
  type: ADDARTICLEFAILURE,
  payLoad: data,
});
