import React from "react";
import { Accordion, AccordionItem } from "react-light-accordion";
import "react-light-accordion/demo/css/index.css";
import { Col, Row } from "reactstrap";

import "../../EmptyNavComponents/supportPage/support.scss";

const Faq = (props) => {
  let faqs = props?.faqSection;

  return (
    <>
      <div className="faq-desktop-section py-8">
        <div className="container">
          <div className=" align-items-center">
            <h3 className="title-text ms-5 FAQ-Support-title">
              Frequently Asked Questions
            </h3>
          </div>
          <Col sm={11} className="mx-auto accordion_title">
            <Row>
              {faqs?.map((value, index) => {
                return (
                  <Col className="col-md-12" sm={4} key={index}>
                    <Accordion atomic={true}>
                      <AccordionItem title={value?.question}>
                        <p>{value?.answer}</p>
                      </AccordionItem>
                    </Accordion>
                  </Col>
                );
              })}
            </Row>
          </Col>
          {false && (
            <div className="d-flex justify-content-center pt-5">
              <button className="text-btn faq-btn">See More</button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Faq;
