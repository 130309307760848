import Loader from "../../../assets/images/Loader/Loading.gif";
import styles from "./Loading.module.scss";

export default function Loading() {
  return (
    <div className="py-8">
      <img src={Loader} className={styles.loadingImage} alt="" />
    </div>
  );
}
